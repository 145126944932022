<!-- 账单收支表格 -->
<template>
  <div class="billtable">
    <table>
      <tr>
        <th>时间</th>
        <th>金额</th>
        <th>收入/支出</th>
        <th>说明</th>
      </tr>
      <template v-for='item,index in popList&&popList.listInfo&&popList.listInfo.List?popList.listInfo.List:0'>
        <tr class='cnt'>
          <td>{{item.OperateDate | filltime}}</td>
          <td>{{item.DepositAmount}}</td>
          <td>{{item.PrestoreType | filltype}}</td>
          <td>{{item.Remark}}</td>
        </tr>
      </template>
    </table>
    <div class="page">
      <button class="back"  @click='back'>上一页 </button>
      <el-pagination
        @current-change='pages'
        :page-size='10'
        background
        layout="pager"
        :current-page.sync='pageNum'
        :total="TotalCount">
      </el-pagination>
      <button  class="next"  @click='next'>下一页</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'billtable',
  props: {
    popList:Object
  },
  data(){
    return {
      // 分页
      pageNum:1,
      TotalCount:null,
    }
  },
  methods:{
    pages(v){
      // 分页回调
      this.$emit('exportPages',v)
    },
    back(){
      // 分页回退
      this.pageNum-=1
      if(this.pageNum<1){
        this.pageNum=1
      }
      this.pages(this.pageNum)
    },
    next(){
      // 分页前进
      this.pageNum+=1
      if(this.pageNum>=Math.ceil(this.TotalCount/10)){
        this.pageNum=Math.ceil(this.TotalCount/10)
      }
      this.pages(this.pageNum)
    },
  },
  mounted(){
  },
  watch:{
    popList(n,o){
      if(n){
        this.TotalCount = n.listInfo.TotalCount
      }
    }
  },
  filters:{
    filltime(v){
      return v.split('T')[0]+' '+v.split('T')[1]
    },
    filltype(v){
      if(v==12){
        return '预存款支付'
      }
      if(v==13){
        return '预存款充值'
      }
      if(v==14){
        return '余额转为预存款金额'
      }
    }
  }
}
</script>
<style  lang="scss" scoped>
.billtable {
  width: 1010px;float:right;background: #fff;margin-top:1px;
  table {
    width: 970px;margin:0 auto;border-collapse: collapse;margin-top:10px;
    tr {
      th {
        background: #f9fafe;height: 49px;line-height: 49px;color:#7f8287;width: 25%;
      }
    }
    .cnt {
      td {
        height: 50px;text-align: center;border-bottom:1px solid #f0f0f0;color:#303030;font-size: 14px;
      }
      &:last-child {
        td {
          border-bottom:0px;
        }
      }
    }
  }
  .page {
    height: 80px;background: #fff;width: 1010px;float:right;display: flex;align-items: center;justify-content: center;
    .back,.next {
      height: 28px;display: inline-block;vertical-align: -4px;background: none;border:1px solid #cccccc;font-size: 12px;width: 75px;text-align: center;border-radius: 5px;color:#ccc;
      &:hover {
        background-color: #ccc!important;
        color:#797979!important;
      }
    }
    /deep/.el-pagination {
      display: inline-block;
      .number {
        color:#ccc;
        &:hover {
          color:#797979!important;
        }
      }
      .el-icon-more {
        display: none;
      }
      .active {
        background-color: #ccc!important;
        color:#797979!important;
      }
    }
  }
}
</style>
