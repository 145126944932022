<!-- 个人中心预存余额 -->
<template>
  <div class="userprice">
    <usercenterhr class='usercenterhr'></usercenterhr>
    <usercenterlt class='usercenterlt' :nav='3'></usercenterlt>
    <div class="rttop">
      <usercentertitle class='lt' title='预存余额'></usercentertitle>
      <button class="safe" @click='linkSafe'><svg><use xlink:href="#iconanquanzhongxin"></use></svg>安全中心</button>
    </div>
    <div class="prices">
      <div class="top">
        <span>&yen;</span>
        <p>{{initData?initData.DepositBalance:0}}</p>
        <button @click='rechargelink'>充值</button>
      </div>
      <button class="use" @click='linkIndex'>去使用>></button>
    </div>
    <div class="listtop">
      <usercentertitle class='lt' title='账单明细'></usercentertitle>
      <!-- <button class="search"><svg><use xlink:href="#iconziyuan13"></use></svg></button> -->
      <div class="times">
        <el-date-picker
          v-model="valueDate"
          type="date"
          placeholder="开始时间">
        </el-date-picker>
        <el-date-picker
          v-model="valueDate1"
          type="date"
          placeholder="结束时间">
        </el-date-picker>
      </div>
    </div>
    <billtable @exportPages='exportPages' :popList='initData'></billtable>
  </div>
</template>
<script>
import usercenterhr from '@/components/usercenter-hr/usercenter-hr.vue';//顶部hr1
import usercenterlt from '@/components/usercenter-lt/usercenter-lt.vue';//左侧栏目1
import usercentertitle from '@/components/usercenter-title/usercenter-title.vue';//内容标题
import billtable from '@/components/billtable/billtable.vue';//账单收支表格
export default {
  name: 'userprice',
  props: {
    msg: String
  },
  components: {
    usercenterhr,//1
    usercenterlt,//1
    usercentertitle,
    billtable
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
          name: "description",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        },
        {
          name: "Keywords",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        }
      ],
    }
  },
  data(){
    return {
      htmltitle:'预存余额-微微定',
      valueDate:'',
      valueDate1:'',
      initData:{},//初始化数据对象
    }
  },
  methods:{
    rechargelink(){
      // 充值跳转
      this._router({then:()=>{
        this.$router.push('/userrecharge.html')
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/userrecharge.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    linkSafe(){
      // 安全中心
      this._router({then:()=>{
        this.$router.push('/safecenter.html')
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/safecenter.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    exportPages(v){
      // 分页跳转
      this.initDataGet({StartDate:this.valueDate?new Date(this.valueDate).toLocaleDateString():'',EndDate:this.valueDate1?new Date(this.valueDate1).toLocaleDateString():'',pageIndex:v,pageSize:10})
    },
    linkIndex(){
      // 去使用跳转
      this._router({then:()=>{
        this.$router.push('/index.html')
      }})
    },
    initDataGet(parms){
      // 初始化数据
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainCusdepositRecordList?StartDate='+parms.StartDate+'&EndDate='+parms.EndDate+'&pageIndex='+parms.pageIndex+'&pageSize='+parms.pageSize,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.initData = res.data.Data
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
      },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.initDataGet({StartDate:'',EndDate:'',pageIndex:1,pageSize:10})
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  watch:{
    valueDate(n,o){
      if(n){
        if(this.valueDate1){
          // console.log(new Date(n).getTime(),new Date(this.valueDate1).getTime())
          if(new Date(this.valueDate1).getTime()<new Date(n).getTime()){
            this.$Message({
                message: '结束日期不能小于开始日期',
                type: 'warning',
                duration:1500,
            });
            this.valueDate = ''
            return;
          }
          this.initDataGet({StartDate:new Date(n).toLocaleDateString(),EndDate:new Date(this.valueDate1).toLocaleDateString(),pageIndex:1,pageSize:10})
        }else {
          this.initDataGet({StartDate:new Date(n).toLocaleDateString(),EndDate:'',pageIndex:1,pageSize:10})
        }
      }else{
        if(this.valueDate1){
          this.initDataGet({StartDate:'',EndDate:new Date(this.valueDate1).toLocaleDateString(),pageIndex:1,pageSize:10})
        }else{
          this.initDataGet({StartDate:'',EndDate:'',pageIndex:1,pageSize:10})
        }
      }
    },
    valueDate1(n,o){
      if(n){
        if(this.valueDate){
          // console.log(new Date(n).getTime(),new Date(this.valueDate).getTime())
          if(new Date(n).getTime()<new Date(this.valueDate).getTime()){
            this.$Message({
                message: '结束日期不能小于开始日期',
                type: 'warning',
                duration:1500,
            });
            this.valueDate1 = ''
            return;
          }
          this.initDataGet({StartDate:new Date(this.valueDate).toLocaleDateString(),EndDate:new Date(n).toLocaleDateString(),pageIndex:1,pageSize:10})
        }else {
          this.initDataGet({StartDate:'',EndDate:new Date(n).toLocaleDateString(),pageIndex:1,pageSize:10})
        }
      }else{
        if(this.valueDate){
          this.initDataGet({StartDate:new Date(this.valueDate).toLocaleDateString(),EndDate:'',pageIndex:1,pageSize:10})
        }else{
          this.initDataGet({StartDate:'',EndDate:'',pageIndex:1,pageSize:10})
        }
      }
    }
  }
}
</script>
<style  lang="scss" scoped>
.userprice {
  width: 1200px;margin:0 auto;zoom:1;padding-bottom:20px;
  .usercenterhr {
    width: 1200px;margin:0 auto;margin-top:20px;margin-bottom:10px;position: relative;z-index: 9;
  }
  .usercenterlt {
    float:left;width: 180px;margin-top:-10px;
  }
  .rttop {
    width: 1010px;height: 60px;float:right;background: #fff;margin-top:-10px;
    .lt {
      float:left;
    }
    .safe {
      color:#297df4;background: none;float:right;font-size: 14px;margin-top:22px;margin-right: 20px;
      svg {
        fill: #297df4;width: 20px;height: 20px;vertical-align: -5px;margin-right: 5px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .prices {
    width: 1010px;height: 150px;float:right;background: #fff;margin-top:1px;
    .top {
      display: flex;justify-content: center;height: 100px;align-items: center;
      span {
        font-size: 24px;color:#333;margin-top:20px;margin-right: 5px;
      }
      p {
        font-size: 46px;color:#333;margin-right: 10px;
      }
      button {
        font-size: 18px;background: none;margin-top:20px;font-weight:bold;color:#cb4f59;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .use {
      display: block;margin:0 auto;background: none;color:#2e57af;font-size: 14px;font-weight: bold;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .listtop {
    width: 1010px;height: 60px;float:right;background: #fff;margin-top:10px;
    .lt {
      float:left;
    }
    .search {
      float:right;margin-right: 20px;width: 40px;height: 40px;background: none;border:1px solid #DCDFE6;border-radius: 5px;margin-top:10px;
      svg {
        fill: #DCDFE6;
      }
      &:hover {
        border-color:#409eff;
        svg {
          fill: #409eff;
        }
      }
    }
    .times {
      float:right;margin-top:10px;margin-right: 10px;
      /deep/.el-input {
        width: 140px;margin-left:5px;
      }
    }
  }
  &:after {
    content: "";display: block;clear:both;
  }
}
</style>
